import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Introduction from "../components/introduction"
import Spacer from "../components/spacer"
import BlogGridItem from "../components/blog-grid-item"

class BlogPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true }>
        <SEO title="Blog" />
        <Introduction 
          title="Insights"
          subtitle={<><p>A collection of all of our latest news, thought pieces, and advice.</p></>}
        />
        <Spacer space="4" />
        <div className="row small-up-1 medium-up-2 shrink-width">
          {data.allWordpressPost.edges.map(({ node }) => (
              <BlogGridItem key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </div>
        <Spacer space="4" />
      </Layout>
    )
  }
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`