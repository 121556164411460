import { Link } from "gatsby"
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import AOS from 'aos';
import 'aos/dist/aos.css';

const Introduction = ({ title, subtitle, link }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="row shrink-width gm-top--6 gm-bottom--4">
      <div className="column small-12 medium-8 large-6">
        <h2 data-aos="fade-up" className="gm-bottom--1">{ title }</h2>
      </div>
      <div className="column small-12 medium-10 large-8">
        <div data-aos="fade-up" data-aos-delay="200">{ subtitle }</div>
        { link ? <Link to={ link } data-aos="fade-in" data-aos-delay="500" data-aos-duration="900" className="button gm-top--1">View work</Link> : '' }
      </div>
    </div>  
  )
}

Introduction.propTypes = {
  title: PropTypes.string,
}

Introduction.defaultProps = {
  title: ``,
  subtitle: ``,
}

export default Introduction    