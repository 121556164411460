import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

const BlogGridItem = ({ image, title, link }) => (
    <div className="column carousel-item gm-bottom--2">
      <img src={ image } alt={ title } className="width-100 gm-bottom--1" />
      <Link to={ link }><p className="p--large no-margin-bottom">{ title }</p></Link>
      <Link to={ link } className="underline underline--white gm-bottom--2">Read more</Link>
    </div>    
)

BlogGridItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
}

BlogGridItem.defaultProps = {
  image: ``,
  title: ``,
  link: ``,
}

export default BlogGridItem